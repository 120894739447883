import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { brand } from '../../../js/localizations/current-locale';

import devices from '../../../js/styles/Devices';
import SectionWrapper from '../../common/SectionWrapper';
import CurrentCompareCardBlock from '../../blocks/CurrentCompareCardBlock';
import CurrentCompareCardListBlock from '../../blocks/CurrentCompareCardListBlock';
import style from './style';

const Header = styled.h2`
  && {
    display: flex;
    align-items: center;
    text-align: ${(props) => props.position};
    font-family: var(--font-family);
    justify-content: ${(props) => props.position};
    font-size: var(--h2-desktop-font-size);
    letter-spacing: ${style.letterSpacing};
    color: ${(props) => props.titleColor};

    @media ${devices.downFromTablet} {
      font-size: var(--h2-mobile-font-size);
    }
  }
`;

const Dot = styled.div`
  && {
    display: ${style.titleDotVisibility};
    background: #76d788;
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 1rem;
    border-radius: 50%;
    animation: blinker 1.6s ease infinite;

    @keyframes blinker {
      50% {
        opacity: 0;
      }
    }
    @media ${devices.downFromTablet} {
      font-family: var(--font-family);
      display: none;
    }
  }
`;

const TitleLine = styled.div`
  && {
    margin: 0 auto;
    width: 7rem;
    height: 3px;
    background-color: var(--sambla-new-red);
    animation:enter .5s cubic-bezier(.25,.46,.45,.94) both;
    animation-delay: 1s;
  }
`;

const SectionContainer = styled(SectionWrapper)`
  && {
    font-family: var(--font-family);
    padding: 2.5em 1em;

    h2 {
      font-family: var(--font-family);
      font-weight: var(--has-font-weight);
    }
  }
`;

const CurrentCompareSection = ({ acf }) => {
  const { titlePosition, titleColor } = acf.sectionSettings;
  const isDigifinans = (brand === 'digifinans');
  const isRahoitu = (brand === 'rahoitu');

  const items = [];
  for (let i = 0; i < acf.cards.length; i += 1) {
    if (isDigifinans || isRahoitu) {
      items.push(
        <CurrentCompareCardListBlock
          cardData={acf.cards[i]}
          titleColor={titleColor}
        />,
      );
    } else {
      items.push(
        <CurrentCompareCardBlock cardData={acf.cards[i]} />,
      );
    }
  }

  const responsiveDigifinans = {
    0: { items: 1 },
    718: { items: 2 },
    1000: { items: 3 },
  };

  const responsive = {
    0: { items: 1 },
    718: { items: 2 },
    1000: { items: 3 },
    1300: { items: 4 },
  };

  const responsiveConfig = isDigifinans ? responsiveDigifinans : responsive;

  return (
    <SectionContainer
      settings={acf.sectionSettings}
      noContainer
    >
      {acf.title && (
        <Header
          position={titlePosition}
          titleColor={titleColor}
        >
          <Dot />
          {acf.title}
        </Header>
      )}
      {acf.sectionSettings?.titleLine && (
        <TitleLine />
      )}

      <AliceCarousel
        items={items}
        autoHeight
        buttonsDisabled
        infinite
        duration={250}
        swipeDisabled={false}
        mouseDragEnabled
        dotsDisabled={false}
        responsive={responsiveConfig}
      />

    </SectionContainer>
  );
};
CurrentCompareSection.propTypes = {
  acf: PropTypes.shape({
    title: PropTypes.string,
    cards: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.string,
        max: PropTypes.string,
        min: PropTypes.string,
        savings: PropTypes.string,
      }),
    ).isRequired,
    sectionSettings: PropTypes.shape(),
  }),
};

CurrentCompareSection.defaultProps = {
  acf: {},
};

export default CurrentCompareSection;
